import React, { useState } from "react"
import { Dropdown, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import {
  ALL_PRIVACY,
  PRIVACY_NOT_SHARED,
  PRIVACY_PASSWORD_PROTECTED,
  PRIVACY_PAY_WALLED,
  PRIVACY_PRIVATE,
  PRIVACY_UNLISTED,
  WHO_CAN_CLONE,
  WHO_CAN_MODIFY,
  WHO_CAN_MODIFY_JUST_ME,
  WHO_CAN_MODIFY_THESE_USERS,
} from "../Service/moreOprtionsHelper"
import { changeHypertextProtocol, getUserName } from "../Service/docHelper"

const Privacy = ({
  docPrivacy,
  setDocPrivacy,
  whoCanModify,
  setWhoCanModify,
  privacyPassword,
  whoCanCopy,
  setWhoCanCopy,
  copyPassword,
  setCopyPassword,
  previewUrl,
  copyPayment,
  setCopyPayment,
}) => {
  const [addModifyUser, setaddModifyUser] = useState(false)

  const handleModifyUserAdd = () => {
    setaddModifyUser(!addModifyUser)
  }

  const filterDocPrivacy = privacy => {
    let result = ALL_PRIVACY.filter(p => {
      return p.value === privacy
    })

    return result.length
      ? result[0]
      : {
          value: PRIVACY_NOT_SHARED,
          label: "Me Only; not shared",
          icon: "bi bi-incognito",
        }
  }
  const filterDocModifier = modify => {
    let result = WHO_CAN_MODIFY.filter(p => {
      return p.value === modify
    })

    return result.length
      ? result[0]
      : {
          value: WHO_CAN_MODIFY_JUST_ME,
          label: "Just me",
          icon: "bi bi-person-fill",
        }
  }
  const filterWhoCanCopy = clone => {
    let result = WHO_CAN_CLONE.filter(p => {
      return p.value === clone
    })

    return result.length
      ? result[0]
      : {
          value: "Nobody",
          label: "Nobody (Private)",
          icon: "bi bi-incognito",
        }
  }

  const defaultPrivacy = filterDocPrivacy(docPrivacy)
  const defaultWhoCanModify = filterDocModifier(whoCanModify)
  const defaultWhoCanClone = filterWhoCanCopy(whoCanCopy)

  return (
    <div className="dh-block-body">
      <strong>
        <i className="bi bi-eyeglasses" /> Who can view and use this?
      </strong>
      <div className="mb-3">
        <div className="row row-cols-sm-auto g-2 align-items-center">
          <div className="col-12">
            <Dropdown className="socialPermission">
              <OverlayTrigger placement="top" overlay={<Tooltip>{defaultPrivacy.label}</Tooltip>}>
                <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
                  <>
                    <i className={defaultPrivacy.icon} /> {defaultPrivacy.label}
                  </>
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu>
                {ALL_PRIVACY.map((AllPrivacy, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      value={AllPrivacy.value}
                      href="#"
                      onClick={() => {
                        setDocPrivacy(AllPrivacy.value)
                      }}
                    >
                      <i className={AllPrivacy.icon} /> {AllPrivacy.label}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          {docPrivacy === PRIVACY_PRIVATE && (
            <div className="col-12 socialPermissionOpts socialPermissionInvitationOnly">
              <OverlayTrigger placement="top" overlay={<Tooltip>Invite User</Tooltip>}>
                <button
                  type="button"
                  className="btn btn-sm btnInviteUser doc-privacy-opt doc-privacy-opt-private mb-1 mb-sm-0"
                >
                  <span>
                    <i className="bi bi-person-plus-fill" />
                  </span>
                </button>
              </OverlayTrigger>
            </div>
          )}
          {docPrivacy === PRIVACY_PASSWORD_PROTECTED && (
            <div className="col-12 socialPermissionOpts socialPermissionPasswordProtected">
              <div className="input-group">
                <span className="input-group-text">Access Password:</span>
                <Form.Control
                  type="text"
                  name="privacy_password"
                  className="form-control"
                  defaultValue={privacyPassword}
                />
              </div>
            </div>
          )}
          {docPrivacy === PRIVACY_UNLISTED && docPrivacy !== "" && (
            <div className="col-12 socialPermissionOpts socialPermissionAnyonewithURL">
              <a href={previewUrl} target="_blank">
                <i className="bi bi-link-45deg" /> {previewUrl}
              </a>
            </div>
          )}
        </div>
      </div>

      {docPrivacy === PRIVACY_PAY_WALLED && (
        <div className="socialPermissionOpts socialPermissionPaywalled mb-4">
          <div className="mb-3">
            Membership Paywalled, Lead Captured, or Class Required
            <div className="input-group">
              <span className="input-group-text">To access, viewer must</span>
              <select className="form-select paywallToAccess">
                <option value="beCaptured">be captured (Any Contact)</option>
                <option value="beTaggedWith">be tagged with...</option>
              </select>
            </div>
          </div>
          <div className="mb-3 paywallToAccessTaggedWithContainer d-none">
            <div className="bootstrap-tagsinput">
              <input type="text" placeholder="" />
              <button className="btn btn-sm btn-primary btnSaveTag">
                <i className="fa fa-save" />
              </button>
            </div>
            <input type="text" name="paywallToAccessTaggedWith" className="paywallToAccessTaggedWith" />
          </div>
          <div className="mb-3">
            Signup Page: If viewer is not yet allowed, send them to...{" "}
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip>
                  Signup page to capture them as a lead, or for them to pay, or to sign up for a class, etc.
                </Tooltip>
              }
            >
              <i className="bi bi-question-circle-fill" />
            </OverlayTrigger>
            <div className="input-group">
              <span className="input-group-text">Membership Page:</span>
              <select className="form-select">
                <option>== List of Docs ==</option>
              </select>
            </div>
          </div>
        </div>
      )}

      <div className="mt-4">
        <strong>
          <i className="bi bi-pencil-square" /> Who can modify this?
        </strong>
      </div>
      <div className="mb-2">
        <Dropdown className="socialPermission">
          <OverlayTrigger placement="top" overlay={<Tooltip>{defaultWhoCanModify.label}</Tooltip>}>
            <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
              <>
                <i className={defaultWhoCanModify.icon} /> {defaultWhoCanModify.label}
              </>
            </Dropdown.Toggle>
          </OverlayTrigger>
          <Dropdown.Menu>
            {WHO_CAN_MODIFY.map((whModify, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  value={whModify.value}
                  href="#"
                  onClick={() => {
                    setWhoCanModify(whModify.value)
                  }}
                >
                  <i className={whModify.icon} /> {whModify.label}
                </Dropdown.Item>
              )
            })}
          </Dropdown.Menu>
        </Dropdown>
      </div>

      {whoCanModify === "TheseUsers" && (
        <div className="mb-2 whoCanModifyTheseUsers">
          <OverlayTrigger placement="top" overlay={<Tooltip>Add User</Tooltip>}>
            <a onClick={handleModifyUserAdd} className="btnSocializer btnAddUserToModifyDoc">
              <i className="bi bi-person-plus-fill" />
            </a>
          </OverlayTrigger>
        </div>
      )}

      {addModifyUser === true && (
        <div className="who_can_modify_these_users_container">
          <div className="input-group">
            <Form.Control
              className="form-control"
              type="text"
              placeholder="Any email or Username"
              autoComplete="off"
            />
            <button type="button" className="btnAddUserToTeam btn btn-secondary">
              <i className="bi bi-person-plus-fill" /> Add User
            </button>
          </div>
        </div>
      )}

      <div className="mt-4">
        <strong>
          <i className="bi bi-files rotate-180" /> Who can Copy / Clone this?
        </strong>
      </div>
      <div className="mb-1">
        <div className="row row-cols-sm-auto g-2 align-items-center">
          <div className="col-12">
            <Dropdown className="whoCanCopyClone">
              <OverlayTrigger placement="top" overlay={<Tooltip>{defaultWhoCanClone.label}</Tooltip>}>
                <Dropdown.Toggle variant="" id="dropdown-basic" className="btn-default">
                  <>
                    <i className={defaultWhoCanClone.icon} /> {defaultWhoCanClone.label}
                  </>
                </Dropdown.Toggle>
              </OverlayTrigger>
              <Dropdown.Menu>
                {WHO_CAN_CLONE.map((whClone, index) => {
                  return (
                    <Dropdown.Item
                      key={index}
                      value={whClone.value}
                      href="#"
                      onClick={() => {
                        setWhoCanCopy(whClone.value)
                      }}
                    >
                      <i className={whClone.icon} /> {whClone.label}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown.Menu>
            </Dropdown>
          </div>
          <div className="col-12">
            {whoCanCopy === "Anyone with the Password" && (
              <div className="input-group who_can_copy_password">
                <span className="input-group-text">Clone Password:</span>
                <Form.Control
                  type="text"
                  className="form-control"
                  name="copy_password"
                  value={copyPassword}
                  onChange={event => setCopyPassword(event.target.value)}
                />
              </div>
            )}
            {whoCanCopy === "Anyone with Payment" && (
              <div className="input-group who_can_copy_payment">
                <span className="input-group-text">$</span>
                <input
                  type="text"
                  className="form-control who_can_copy_payment_input text-start"
                  name="copy_payment"
                  value={copyPayment}
                  onChange={event => setCopyPayment(event.target.value)}
                  style={{ textAlign: "right" }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {whoCanCopy && whoCanCopy !== "Nobody" && (
        <div className="mt-2 input-group who_clone_template">
          <a href={`${previewUrl}?clonetemplate=1`} target="_blank">
            <i className="bi bi-link-45deg" /> {`${previewUrl}?clonetemplate=1`}
          </a>{" "}
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                Share this URL with ?clonetemplate=1 at the end to let people clone their own editable copy of
                this doc into their account.
              </Tooltip>
            }
          >
            <i className="bi bi-question-circle-fill" />
          </OverlayTrigger>
        </div>
      )}
    </div>
  )
}

export default Privacy
